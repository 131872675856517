const links = [
  {
    label: "Home",
    url: "/",
  },
  {
    label: "Sobre",
    url: "/about/",
  },
  {
    label: "Notícias",
    url: "/blog/",
  },
  {
    label: "Contatos",
    url: "/contact/",
  },
]

export default links
