const links = [
  {
    label: "Facebook",
    url: `https://www.facebook.com/vicente.lustosa.50?mibextid=ZbWKwL`,
  },
  {
    label: "Twitter",
    url: `https://twitter.com/lustosa_50`,
  },
  {
    label: "Instagram",
    url: `https://instagram.com/vicente.lustosa.50?igshid=ZDdkNTZiNTM=`,
  },
  {
    label: "Youtube",
    url: `https://www.youtube.com/@vicentelustosapereira6531`,
  },
]

export default links
